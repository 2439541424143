import {API} from "@/env";
import axios from "@/api/axios";
import router from "@/router";
import Vuex from "@/store";

export default {
    update_data(data) {

        const options = {
            method: 'post',
            url: API + 'api/updateUser',
            headers: {'content-type': 'application/json' },
            data: data,
        };
        return new Promise ((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error)
            });
        });

    },
    get_user_ip() {
        const options = {
            method: 'GET',
            url: API + 'api/user_ip',
            headers: {'content-type': 'application/json' },
        };

        return new Promise((resolve, reject) => {
            axios(options).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                reject(error);
            });
        });
    },
}

export const get_user_country = () => {
    const options = {
        method: 'GET',
        url: API + '/api/details',
        headers: {'content-type': 'application/json' },
    };

    return new Promise((resolve, reject) => {
        axios(options).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}

export const insert_data = (data) => {

    const options = {
        method: 'post',
        url: API + 'api/addNew',
        headers: {'content-type': 'application/json' },
        data: data,
    };
    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });

}


/**
 * This api for admin login route
 * @data admin info Email & Password
 */
export const admin_login = (data) =>  {
    const options = {
        method: 'POST',
        url: API + 'api/admin/login/now',
        headers: {'content-type': 'application/json'},
        data: data,
    };
    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
            localStorage.setItem('token', response.data.token);
            // Vuex.commit('setToken', response.data.token);

        }).catch(error => {
            reject(error.response)
        });
    });
}

export const log_out = () => {
    router.push('/').then(() =>  localStorage.removeItem('token'))
}


/**
 * This api for get script code
 */
export const get_script = ()  => {

    const options = {
        method: 'GET',
        url: API + 'api/get-new-script',
        headers: {'content-type': 'application/json',  Authorization: 'Bearer '},
    };
    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });

}